export const roomForm = [
  {
    label: 'room_name',
    keyInRoom: 'display_name',
  },
  {
    label: 'room_name_en',
    keyInRoom: 'display_name_en',
  },
  {
    label: 'floor',
    keyInRoom: 'floor',
  },
  {
    label: 'zone',
    keyInRoom: 'zone',
  },
  {
    label: 'building',
    keyInRoom: 'building',
  },
  {
    label: 'cells_num',
    keyInRoom: 'cells_num',
  },
  {
    label: 'sinks_num',
    keyInRoom: 'sinks_num',
  },
  {
    label: 'urinals_num',
    keyInRoom: 'urinals_num',
  },
  {
    label: 'toilet_paper_size',
    keyInRoom: 'toilet_paper_size',
  },
]

export const shiftForm = [
  {
    label: 'shift_number',
    keyInShift: 'shift_number',
    inputType: 'number',
    type: 'primary',
  },
  {
    label: 'shift_name',
    keyInShift: 'shift_name',
    inputType: 'text',
    type: 'primary',
  },
  {
    label: 'start_time',
    keyInShift: 'start_hour',
    inputType: 'number',
    type: 'primary',
  },
  {
    label: 'end_time',
    keyInShift: 'end_hour',
    inputType: 'number',
    type: 'primary',
  },
  {
    label: 'min_sla',
    keyInShift: 'min_sla',
    inputType: 'number',
    defaultValue: '15',
  },
  {
    label: 'max_sla',
    keyInShift: 'max_sla',
    inputType: 'number',
    defaultValue: '120',
  },
  {
    label: 'arrivals',
    keyInShift: 'day_arrivals',
    inputType: 'number',
    defaultValue: '3',
  },
  {
    label: 'save_arrivals',
    keyInShift: 'save_arrivals',
    inputType: 'number',
    defaultValue: '3',
  },
  {
    label: 'complaints_percent',
    keyInShift: 'complaints_percent',
    inputType: 'number',
    defaultValue: '35',
  },
  {
    label: 'sla_percent',
    keyInShift: 'sla_percent',
    inputType: 'number',
    defaultValue: '35',
  },
  {
    label: 'arrivals_percent',
    keyInShift: 'arrivals_percent',
    inputType: 'number',
    defaultValue: '30',
  },
  {
    label: 'complaints_NC',
    keyInShift: 'complaints_percent_no_visits',
    inputType: 'number',
    defaultValue: '20',
  },
  {
    label: 'SLA_NC',
    keyInShift: 'sla_percent_no_visits',
    inputType: 'number',
    defaultValue: '60',
  },
  {
    label: 'arrivals_NC',
    keyInShift: 'arrivals_percent_no_visits',
    inputType: 'number',
    defaultValue: '20',
  },
]

export const daysOfWeek = [
  {
    label: 'sunday',
    currValue: false,
    value: 1,
  },
  {
    label: 'monday',
    currValue: false,
    value: 2,
  },
  {
    label: 'tuesday',
    currValue: false,
    value: 3,
  },
  {
    label: 'wednesday',
    currValue: false,
    value: 4,
  },
  {
    label: 'thursday',
    currValue: false,
    value: 5,
  },
  {
    label: 'friday',
    currValue: false,
    value: 6,
  },
  {
    label: 'saturday',
    currValue: false,
    value: 7,
  },
]

export const months = { '1': 'january', '2': 'february', '3': 'march', '4': 'april', '5': 'may', '6': 'june', '7': 'july', '8': 'august', '9': 'september', '10': 'october', '11': 'november', '12': 'december' }

export const levels = ['buzzz', 'franchise', 'company', 'site', 'manager', 'supervisor', 'viewer']
export const siteType = ['survey', 'operation', 'questioner', 'realtime', 'task', 'cleandex']
export const shiftColsToShow = ['shift_number', 'shift_name', 'start_hour', 'end_hour', 'min_sla', 'max_sla', 'complaints_percent', 'sla_percent', 'arrivals_percent']
