<template>
  <transition name="fade">
    <div class="error-msg" v-if="isShown">{{text}}</div>
  </transition>
</template>

<script>
export default {
    props: {
        isShown: { type: Boolean, required: true },
        text: { type: String, required: false }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/mixins";

.error-msg {
  border:unset !important;
  @include error-msg;
}
</style>